.AuthScreen {
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color:#1A202C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
