.dark-scrollbar::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.dark-scrollbar::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.dark-scrollbar::-webkit-scrollbar-thumb {
  background: #5C7080;
  border: 1px solid #576a79;
  border-radius: 0px;
}
.dark-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #738694;
  border: 1px solid #5d6f7d;
}
.dark-scrollbar::-webkit-scrollbar-thumb:active {
  background: #394B59;
  border: 1px solid #303f4b;
}
.dark-scrollbar::-webkit-scrollbar-thumb:disabled { background: rgba(0,0,0,0.15); }
.dark-scrollbar::-webkit-scrollbar-track {
  background: #293742;
  border: 0px none #ffffff;
  border-radius: 0px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
}
.dark-scrollbar::-webkit-scrollbar-track:hover { background: #293742; }
.dark-scrollbar::-webkit-scrollbar-track:active { background: #202B33; }
.dark-scrollbar::-webkit-scrollbar-track:disabled {  background: rgba(0,0,0,0.15); }
.dark-scrollbar::-webkit-scrollbar-corner { background: #182026; }
.dark-scrollbar::-webkit-scrollbar-corner:disabled {  background: rgba(0,0,0,0.15); }

.light-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.light-scrollbar::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.light-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.15);
  border-radius: 0px;
}
.light-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.25);
}
.light-scrollbar::-webkit-scrollbar-thumb:active {
  background: rgba(0,0,0,0.35);
}
.light-scrollbar::-webkit-scrollbar-thumb:disabled {
  background: rgba(0,0,0,0.1);
}
.light-scrollbar::-webkit-scrollbar-track {
  background: #F5F8FA;
  border-left: 1px solid rgba(16, 22, 26, 0.15);
  border-radius: 0px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
}
.light-scrollbar::-webkit-scrollbar-track:active { background: #EBF1F5; }
.light-scrollbar::-webkit-scrollbar-track:disabled {  background: rgba(0,0,0,0.15); }
.light-scrollbar::-webkit-scrollbar-corner { background: rgba(0,0,0,0.1); }
.light-scrollbar::-webkit-scrollbar-corner:disabled {  background: rgba(0,0,0,0.1); }
